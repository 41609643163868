<template>
  <div class="new-center">
    <div class="content-left">
      <div style="text-align: left">
        <div class="left-title">集团产业</div>
        <div class="left-sub-title">COLLECTIVE INDUSTRY</div>
        <div class="tab-menu">
          <div v-for="(item, i) in tabMenu" :key="i">
            <div
              v-if="item.level != 0"
              class="menu-item"
              :class="currentTab.code == item.code ? 'active-class' : ''"
              @click="changeMenu(item.name, currentTab.code, item.code)"
            >
              <i
                :class="
                  currentTab.code == item.code && isOpen
                    ? 'el-icon-caret-bottom'
                    : 'el-icon-caret-right'
                "
              ></i>
              <span>{{ item.name }}</span>
            </div>
            <div v-if="item.children && currentTab.code == item.code && isOpen">
              <div v-for="(item2, i2) in item.children" :key="i2">
                <div
                  class="menu-item"
                  style="padding-left: 30px"
                  :class="currentAct == item2.id ? 'active-class' : ''"
                  @click="changeCompany(item2.label, item2.id, item.code)"
                >
                  <i class="el-icon-caret-right"></i>
                  <span>{{ item2.name }}</span>
                </div>
                <div v-if="item2.children">
                  <div v-for="(item3, i3) in item2.children" :key="i3">
                    <div
                      class="menu-item"
                      style="padding-left: 60px"
                      :class="currentAct == item3.id ? 'active-class' : ''"
                      @click="changeCompany(item3.label, item3.id, item.code)"
                    >
                      <i class="el-icon-caret-right"></i>
                      <span>{{ item3.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div class="content-title">
        <span class="title-line">{{ currentTab.name }}</span>
      </div>
      <div class="content-info">
        <!-- 集团产业 -->
        <div v-if="currentTab.num == 0" class="group-industry">
          <div class="company-type-card">
            <img src="@/assets/index/ggfw.jpg" alt="" />
            <div class="company-type-title">公用事业</div>
          </div>
          <div class="company-type-card">
            <img src="@/assets/index/szjsgc.jpg" alt="" />
            <div class="company-type-title">市政工程</div>
          </div>
          <div class="company-type-card">
            <img src="@/assets/index/csjsgh.png" alt="" />
            <div class="company-type-title">商业运营</div>
          </div>
          <div class="company-type-card">
            <img src="@/assets/index/cytz.png" alt="" />
            <div class="company-type-title">产业投资</div>
          </div>
          <div class="company-type-card">
            <img src="@/assets/index/yllh.jpg" alt="" />
            <div class="company-type-title">人才发展</div>
          </div>
        </div>
        <!-- 公用事业 -->
        <div v-if="currentTab.num != 0" class="public">
          <br />
          <div class="company-main" v-if="company">
            <div class="company-main-title">{{ company.companyName }}</div>
            <br />
            <div class="company-main-head">
              <img width="500px" :src="company.headerPic" />
            </div>
            <div class="company-main-content" style="text-align: justify">
              <div
                v-html="company.cpmpanyDec"
                class="ql-editor"
                style="
                  width: 100%;
                  overflow: auto;
                  color: #666666;
                  font-size: 14px;
                  max-width: px;
                  word-break: break-all;
                  font-family: 微软雅黑;
                  line-height: 28px;
                "
              ></div>
            </div>
            <div class="company-news">
              <div
                style="
                  width: 45%;
                  height: 300px;
                  display: inline-block;
                  margin-left: 10px;
                  float: left;
                  margin-top: 60px;
                "
                v-for="item in company.news"
                :key="item.newsId"
                @click="goToDetail(item.newsId)"
              >
                <div style="height: 260px">
                  <img
                    style="width: 100%; height: 100%"
                    :src="item.thumbnail"
                  />
                </div>
                <p
                  style="
                    line-height: 20px;
                    color: #666666;
                    font-size: 14px;
                    font-family: 微软雅黑;
                    font-weight: normal;
                    text-align: center;
                  "
                >
                  {{ item.newsTitle }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllCompanys, getCompanyById } from "../../../js/api/index";
export default {
  data() {
    return {
      tabMenu: [],
      isOpen: true,
      currentAct: 0,
      currentTab: {
        name: "集团产业",
        num: 0,
      },
      company: {},
    };
  },
  watch: {
    $route(to, from) {
      // console.log("router changes！！！", this.$route.query);
      // let type = this.$route.query.type;
      let id = this.$route.query.id;
      let label = this.$route.query.label;
      let code = this.$route.query.code;
      // console.log('idididi->',id, label,code);
      // if (type) {
      //   this.currentTab.name = this.tabMenu[type].name;
      //   this.currentTab.num = type;
      // }
      if (id && code) {
        this.currentTab.num = id;
        this.currentTab.name = label;
        this.currentTab.code = code;
        getCompanyById(id).then((res) => {
          this.company = res.data;
        });
      } else {
        this.currentTab = {
          name: "集团产业",
          num: 0,
          code: "",
          id: 0,
        };
      }
    },
  },
  computed: {},
  methods: {
    getMunuTabs() {
      getAllCompanys().then((res) => {
        // console.log("this is tabMenu:", res.data);
        this.tabMenu = res.data;
      });
    },
    goMenu(i) {
      this.currentTab.name = this.tabMenu[i].name;
      this.currentTab.num = i;
      let type = this.$route.query.type;
      if (type == i) return;
      this.$router.replace({ query: { type: i } }); // 只能用 name
    },
    checkRouter() {
      // let type = this.$route.query.type;
      let id = this.$route.query.id;
      let label = this.$route.query.label;
      let code = this.$route.query.code;
      // console.log(id, label);
      // if (type) {
      //   this.currentTab.name = this.tabMenu[type].name;
      //   this.currentTab.num = type;
      // }
      if (id && code) {
        this.currentTab.name = label;
        this.currentTab.code = code;
        this.currentTab.num = id;
        getCompanyById(id).then((res) => {
          this.company = res.data;
          console.log(res);
        });
      } else {
        this.currentTab = {
          name: "集团产业",
          num: 0,
        };
      }
    },
    changeMenu(label, id, code) {
      if (this.isOpen && this.currentTab.code == code) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      // this.currentTab.name = item.name;
      this.currentTab.code = code;
      // let type = this.$route.query.type;
      // if (type == i) {
      //   return;
      // }
      this.$router.replace({ query: { label: label, id: id, code: code } }); // 只能用 name
      // console.log(label,id,code)
      document.body.scrollTop = document.documentElement.scrollTop = 450;
    },
    changeCompany(label, id, code) {
      this.currentTab.num = 1;
      console.log("--->", label, id, code);
      this.currentTab.name = label;
      this.currentTab.code = code;
      this.currentAct = id;
      if (id) {
        getCompanyById(id).then((res) => {
          this.company = res.data;
          // console.log(res);
          document.body.scrollTop = document.documentElement.scrollTop = 450;
        });
      }
    },
    goToDetail(newsId) {
      this.$router.push({ path: "/newsdetail", query: { id: newsId } });
    },
  },
  mounted() {
    this.getMunuTabs();
  },
  created() {
    this.checkRouter();
  },
};
</script>

<style lang="scss" scoped>
.new-center {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}
.content-left {
  width: 28.6%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 30vh;
  background: #f7f7f7;
  .left-title {
    font-family: 微软雅黑;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    color: #003f99;
    padding: 0 0 5px 0;
    display: block;
    padding: 0 0 5px 0;
  }
  .left-sub-title {
    font-family: arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #bababa;
    display: block;
  }
  .tab-menu {
    width: 75%;
    margin-top: 10px;
    .sub-menu {
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      padding: 15px 10px;
      padding-left: 30px;
      border-bottom: 1px solid gainsboro;
    }
  }
  .menu-item {
    cursor: pointer;
    padding: 15px 10px;
    font-size: 16px;
    color: #666666;
  }
  .menu-item {
    border-bottom: 1px solid gainsboro;
  }
  .menu-item:hover,
  .sub-menu:hover {
    color: #003f99;
  }
  .active-class {
    color: #003f99;
  }
}
.content-right {
  width: 71.4%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  .content-title {
    text-align: left;
    border-bottom: 1px solid gainsboro;
    padding: 5px 0 5px 15px;
    color: #003f99;
    position: relative;
  }
  .title-line::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 4px;
    background: #003f99;
    left: 0;
  }
}
.group-industry {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .company-type-card {
    width: 310px;
    margin-bottom: 25px;
    cursor: pointer;
    .company-type-title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
    }
    > img {
      width: 100%;
      height: 185px;
    }
  }
}
.company-main-title {
  font-weight: bold;
}
.childmenu {
  padding-left: 60px;
}
</style>